




import BaseRouter from '@/components/BaseRouter.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'ResourcesModulePage',
  components: {
    BaseRouter,
  },
});
